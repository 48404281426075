var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("narrow-layout", [
    _c(
      "div",
      { staticClass: " flex flex-col space-y-8" },
      [
        _c("network-diagnosis", {
          staticClass: "mx-auto border",
          attrs: {
            "ot-network-test": _vm.otNetworkTest,
            "loading-test": _vm.loadingTest,
          },
        }),
        _c("device-list", {
          staticClass: "mx-auto p-4 border bg-white shadow",
          attrs: { "ot-network-test": _vm.otNetworkTest },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }